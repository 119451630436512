import { render, staticRenderFns } from "./nested-menu.vue?vue&type=template&id=31ad480d&scoped=true&"
import script from "./nested-menu.vue?vue&type=script&lang=js&"
export * from "./nested-menu.vue?vue&type=script&lang=js&"
import style0 from "./nested-menu.vue?vue&type=style&index=0&id=31ad480d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31ad480d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VHover,VIcon,VList,VListItem,VListItemAction,VListItemTitle})
