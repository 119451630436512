<template>
  <v-list
    dense
    class="nested-menu"
    :style="{
      backgroundColor: backgroundColorComputed,
      fontFamily,
      top: topPosition,
    }"
  >
    <v-hover
      v-for="item in items"
      :key="item.id"
      close-delay="200"
      open-delay="200"
    >
      <v-list-item
        slot-scope="{ hover }"
        :style="{
          backgroundColor: hover
            ? lighterColor(backgroundColorComputed)
            : backgroundColorComputed,
          borderColor: item.color,
        }"
      >
        <router-link :to="getTo(item)">
          <v-list-item-title>
            <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
            <div v-if="!collapsed">{{ item.name }}</div>
          </v-list-item-title>

          <v-list-item-action v-if="!collapsed && hasValidChildren(item.children)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-action>
        </router-link>

        <!-- Here is one more level -->
        <NestedMenu
          v-if="hover && hasValidChildren(item.children)"
          :items="item.children"
          :is-client="isClient"
          :background-color="lighterColor(backgroundColorComputed)"
        ></NestedMenu>
      </v-list-item>
    </v-hover>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex';
import shadeColor from "@/helpers/shadeColor";

export default {
  computed: {
    ...mapGetters(['clientId']),
    backgroundColorComputed: function () {
      return this.backgroundColor || (this.isClient ? "#1C1C44" : "#0B5456");
    },
    // clientId: function () {
    //   return this.isClient ? this.$store.getters.clientId : "";
    // },
    fontFamily: function () {
      return this.isClient ? "'Ruda', sans-serif" : "'Mulish', sans-serif";
    },
  },
  data: function () {
    return {
      topPosition: "0px",
    };
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    isClient: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
    },
  },
  methods: {
    getTo: function (item) {
      return {
        name: item.to,
        params: { clientId: this.clientId },
      }
    },
    hasValidChildren: function (children) {
      return Array.isArray(children) && children.length > 0;
    },
    lighterColor: function (hexColor) {
      return shadeColor(hexColor, 25);
    },
  },
  mounted: function () {
    const listItemHeight = 40;
    const viewportLimit = window.innerHeight;
    const { bottom } = this.$el.getBoundingClientRect();
    const topPosition =
      (bottom > viewportLimit
        ? Math.ceil((bottom - viewportLimit) / listItemHeight) * listItemHeight * -1
        : 0);
    this.topPosition = `${topPosition}px`;
  },
  name: "NestedMenu",
};
</script>

<style scoped lang="scss">
.v-list-item {
  position: relative;
  border-left: 7px transparent solid;

  // router-link turns into a <a> tag when rendering
  a {
    align-items: center;
    display: flex;
    flex: 1 1 100%;

    width: 100%;
  }
}

.v-list--dense {
  padding: 0;
}

.v-list--dense .v-list-item:not(.v-list-item__avatar) {
  @include sm-height {
    height: 44px;
  }

  height: 56px;
  padding: 0;
}

.v-list-item__action {
  margin-left: 0 !important;

  .v-icon.v-icon {
    color: white;
    min-width: 32px;
  }
}

.v-list-item__title {
  color: white;
  font-family: inherit;
  font-size: 16px !important;
  line-height: 18px !important;
  text-overflow: unset;
  overflow: unset;
  word-break: unset;
  display: flex;
  align-items: center;

  .v-icon.v-icon {
    box-sizing: border-box;
    color: white;
    margin-left: 1.5rem;
    height: 24px;
    width: 24px;
  }

  div {
    margin-left: 1.5rem;
    white-space: normal;
  }
}

.nested-menu {
  .v-list--dense .v-list-item:not(.v-list-item__avatar) {
    height: 40px;
  }

  & & {
    z-index: 999;
    position: absolute;
    right: initial;
    left: 100%;
    min-width: 255px;
    width: fit-content;

    .v-list-item__title {
      margin-right: 24px;

      div {
        white-space: unset;
      }
    }
  }
}
</style>
